import React, { Suspense } from 'react';
import { Navigate, Outlet, useRoutes } from 'react-router-dom'; // Import useRoutes
import CustomLoader from '../component/common/CustomLoader';
import { useUserContext } from '../context/UserContext';
import { LOGIN, HOME } from '../routes/routes'; // Import DASHBOARD route

import SideNav from './SideNav';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Toolbar } from '@mui/material';
import Dashboard from '../pages/dashboard';
import MainNavbar from '../component/main-navbar';
import FooterDb from '../component/footer-dashboard';
import Layout from './Layout';
import CustomBreadcrumbs from '../component/breadcrumbs';
import ScrollTop from '../component/scrolltop';

const MainLayout = () => {
  const { isLoggedIn } = useUserContext();

  return (
    isLoggedIn ? ( // Render the Dashboard route if logged in
      <Suspense fallback={<CustomLoader />}>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <CssBaseline>
            <Box component="main" sx={{ width: '100%' }}>
            <Toolbar id="back-to-top-anchor" style={{ minHeight: '0px'}} />
              <Layout>
                <MainNavbar />
                <CustomBreadcrumbs />
                <Outlet /> {/* Render other routes */}
                <ScrollTop />
                <FooterDb />
              </Layout>
            </Box>
          </CssBaseline>
        </Box>
      </Suspense>
    ) : (
      <Navigate to={LOGIN} /> // Redirect to the LOGIN route if not logged in
    )
  )
};

export default MainLayout;
