import { createTheme } from "@mui/material";
import { TRUE } from "sass";

const themePrimary = {
    main: '#00268F',
    light: '#DDE1FF',
    dark: '#223DA2',
    contrastText: '#fff',
}

const themeSecondary = {
    main: '#D7B24D',
    light: '#FFF8E3',
    dark: '#9E7401',
    contrastText: '#fff',
}
const fontFamily = "Inter";

export const theme = createTheme({
    palette: {
        primary: themePrimary,
        secondary: themeSecondary
    },
    typography: {
        fontFamily: fontFamily,
    },
})

export const primaryButtonStyle: any = {
    "variant": "contained",
    "disableElevation": true,
    "size": "large",
    "fullWidth": true
}

export const errorButtonStyle: any = {
    "variant": "contained",
    "disableElevation": true,
    "size": "large",
    "fullWidth": true,
    "color":"error"
}

export const primaryTextField: any = {
    "variant": "standard",
    "fullWidth": true
}

