import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import NetworkService from "../../network/NetworkService"
import { Order, OrderFilter } from '../../model/order'

export const orderListThunk = createAsyncThunk(
    '/order-list',
    async (body: any, thunkApi) => await NetworkService.listOrders(body)
)

export const orderSearchThunk = createAsyncThunk(
    '/order-search',
    async (body: any, thunkApi) => await NetworkService.searchOrder(body)
)

export const orderSearchListThunk = createAsyncThunk(
    '/order-searchlist',
    async (body: any, thunkApi) => await NetworkService.searchOrder(body)
)

export const orderSearchFilterThunk = createAsyncThunk(
    '/order-searchlistfilter',
    async (body: any, thunkApi) => await NetworkService.searchOrder(body)  // data grid 
)


const inititalData = {
    orderList: [] as Order[],
    orderFilter: {} as OrderFilter,
    isLoading: false,
    errorMessage: '',
    totalCount: 0,
    rowsPerPage: 10,
    page: 1,
    orderFilterList: [] as Order[],
    tempOrderList: [] as Order[],
    tempPageNumber: 1,
    tempTotalCount: 0,
    searchTerm: '',
    //orderSearchList: [] as Order[]
}

const orderSlice = createSlice({
    name: "order-list",
    initialState: {
        ...inititalData,
    },
    reducers: {
        setOrderFilter: (state, action) => {
            state.orderFilter = action.payload
        },
        setPage: (state, action) => {
            state.page = action.payload
            state.tempPageNumber = action.payload
        },
        setOrderFilterList: (state, action) => {
            state.orderFilterList = action.payload
        },
        setOrderList: (state, action) => {
            state.orderList = action.payload
        },
        setTotalPageCount: (state, action) => {
            state.totalCount = action.payload
        },
        setSearchPageNum: (state, action) => {
            state.page = action.payload
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload
        },
    },
    extraReducers: (builder) => { //api call
        builder
            .addCase(orderListThunk.pending, (state, action) => { //pending means api call is succes
                state.isLoading = true
                state.errorMessage = ''
            })
            .addCase(orderListThunk.fulfilled, (state, action: any) => { // return res
                state.orderList = action.payload?.orders as Order[] //assigning res data to state variable
                state.totalCount = action.payload?.totalRecordCount as any
                state.tempOrderList = action.payload?.orders as Order[]
                state.tempTotalCount = action.payload?.totalRecordCount as any // page count (temp)
                state.isLoading = false
                state.errorMessage = ''
            })
            .addCase(orderListThunk.rejected, (state, action) => { //error , so emp 
                state.orderList = []
                state.totalCount = 0 // pagination
                state.errorMessage = action.error.message ?? ''
                state.isLoading = false
            })

        builder
            .addCase(orderSearchThunk.pending, (state, action) => { //pending means api call is succes
                state.isLoading = true
                state.errorMessage = ''
            })
            .addCase(orderSearchThunk.fulfilled, (state, action: any) => { // return res
                state.orderFilterList = action.payload?.data?.orders?.length ? action.payload.data.orders : []
                state.isLoading = false
                state.errorMessage = ''
            })
            .addCase(orderSearchThunk.rejected, (state, action) => { //error , so emp 
                state.orderFilterList = []
                state.errorMessage = action.error.message ?? ''
                state.isLoading = false
            })

        builder
            .addCase(orderSearchListThunk.pending, (state, action) => {
                state.isLoading = true;
                state.errorMessage = '';
            })
            .addCase(orderSearchListThunk.fulfilled, (state, action: any) => {
                state.orderList = action.payload?.orders as Order[];
                state.isLoading = false;
                state.errorMessage = '';
            })
            .addCase(orderSearchListThunk.rejected, (state, action) => {
                state.orderList = [];
                state.errorMessage = action.error.message ?? '';
                state.isLoading = false;
            });
        builder
            .addCase(orderSearchFilterThunk.pending, (state, action) => {
                state.isLoading = true;
                state.errorMessage = '';
            })
            .addCase(orderSearchFilterThunk.fulfilled, (state, action: any) => {
                state.orderList = action.payload?.orders as Order[];
                state.isLoading = false;
                state.errorMessage = '';
            })
            .addCase(orderSearchFilterThunk.rejected, (state, action) => {
                state.orderList = [];
                state.errorMessage = action.error.message ?? '';
                state.isLoading = false;
            });

    }
})

export const { setOrderFilter, setPage, setOrderFilterList, setOrderList, setTotalPageCount, setSearchPageNum, setSearchTerm } = orderSlice.actions

export default orderSlice.reducer;
