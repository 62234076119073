import { InternalAxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';
import { KEY_X_AUTH_TOKEN, KEY_X_REFRESH_TOKEN, KEY_X_TEMP_ACCESS_TOKEN, SUCCESS_RESPONSE } from '../constants/api-constants';
import { LSK_REFRESH_TOKEN, LSK_TEMP_TOKEN, LSK_TOKEN } from '../constants/local-storage-constants';
import SecureStorage from '../util/SecureStorage';
import rsAxiosInstance from './AxiosConfig';
import { useUserContext } from '../context/UserContext';
import { ResponseType } from '../model/network';
import { useSnackbar } from '../context/SnackbarContext';

const AxiosInterceptor = ({ children }: any) => {
  const { setIsLoggedIn, logout } = useUserContext();
  const { showSnackbar } = useSnackbar();


  // open URLs to exclude headers and 401 errors
  const OPEN_URL_LIST = ['login', 'refresh-token'];
  const AUTHENTICATE = 'login';
  const REFRESH_TOKEN = 'refresh-token';
  const TEMP_ACCESS_TOKEN = 'generate-otp'

  const handleServerError = (error: any) => {
    if (error && error.response && error.response.data && error.response.data.message) {
      return Error(error.response.data.message);
    }
    if (error.message) {
      return Error(error.message);
    }
    return Error("Unknown Error");
  };

  useEffect(() => {
    let isRefreshing: boolean; // Track whether a token refresh is in progress
    let refreshSubscribers: any[] = []; // Array to hold the pending API requests

    const reqInterceptor = rsAxiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig<any>) => {
      let token = SecureStorage.getItem(LSK_TOKEN);
      // open URL list will be excluded
      if (config && config.url) {
        if (!OPEN_URL_LIST.includes(config.url) && token) {
          config.headers['Authorization'] = `Bearer ${SecureStorage.getItem(LSK_TOKEN)}`;

        }
        if (config.url && !config.headers['Content-Type']) {
          config.headers['Content-Type'] = 'application/json';
        }
        if (config.url == REFRESH_TOKEN) {
          let refreshToken = SecureStorage.getItem(LSK_REFRESH_TOKEN);
          config.headers['X-REFRESH-TOKEN'] = refreshToken;
        }

        if (config.url.includes('validate-otp') || config.url.includes('reset-password')) {
          let tempToken = SecureStorage.getItem(LSK_TEMP_TOKEN);
          config.headers['X-TEMP-ACCESS-TOKEN'] = tempToken;
        }

      }

      return config;
    }, error => {
      return Promise.reject(error);
    });

    const resInterceptor = rsAxiosInstance.interceptors.response.use(
      ({ config, data }): any => {
        if (!config || !data) return Promise.reject(Error("Something went wrong"));
        if (!config.url) return Promise.reject(Error("Something went wrong"));

        if (data.status === SUCCESS_RESPONSE) {
          if (config.url === AUTHENTICATE) {
            SecureStorage.setItem(LSK_TOKEN, data.data[KEY_X_AUTH_TOKEN]);
            SecureStorage.setItem(LSK_REFRESH_TOKEN, data.data[KEY_X_REFRESH_TOKEN]);
            // NOTE: In case if custom handling is required then below can be moved to the auth screen
            setIsLoggedIn(true);
          }

          if (config.url === REFRESH_TOKEN) {
            SecureStorage.setItem(LSK_TOKEN, data.data[KEY_X_AUTH_TOKEN]);
          }

          if (config.url === TEMP_ACCESS_TOKEN) {
            // If generateOTP request was successful, set tempAccessToken
            SecureStorage.setItem(LSK_TEMP_TOKEN, data.data[KEY_X_TEMP_ACCESS_TOKEN]);//key-value
            //setTempAccessToken(data.data.temp_access_token);

          }
        }

        const res = new ResponseType(data);

        if (!res.isSuccess) {
          showSnackbar(res.description); // handled errors will be shown here
        }


        return Promise.resolve(res);
      },
      async (error) => {
        if (!error.response) {
          return Promise.reject(handleServerError(error));
        }

        const originalRequest = error.config;
        const reqUrl = error.response.config.url;

        if (error.response.status === 401 && !originalRequest._retry && !OPEN_URL_LIST.includes(reqUrl)) {
          originalRequest._retry = true;

          const retryOriginalRequest = new Promise((resolve) => {
            refreshSubscribers.push(() => resolve(rsAxiosInstance(originalRequest)));
          });

          if (!isRefreshing) {
            isRefreshing = true;

            try {
              const body = {
                refreshToken: SecureStorage.getItem(LSK_REFRESH_TOKEN),
              };

              // Make a request to refresh the access token
              await rsAxiosInstance.get(REFRESH_TOKEN);

              // Update the access token
              // Replay original requests
              refreshSubscribers.forEach((subscriber) => subscriber());
              refreshSubscribers = [];
            } catch (refreshError) {
              console.error("Token refresh failed:", refreshError);
              logout();
            }

            isRefreshing = false;
          }
          return retryOriginalRequest;
        }

        // Return any other error response
        return Promise.reject(handleServerError(error));
      }
    );

    return () => {
      rsAxiosInstance.interceptors.request.eject(reqInterceptor);
      rsAxiosInstance.interceptors.response.eject(resInterceptor);
    };
  }, []);

  return children;
};

export default AxiosInterceptor;
