import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NetworkService from "../../network/NetworkService";
import { Reasons } from "../../model/AddComplaints";

export const fetchReasonsList = createAsyncThunk(
    '/reasons',
    async (_, thunkApi) => await NetworkService.getReasonsList()
);

export const fetchAddComplaintData = createAsyncThunk(
    '/add-complaint',
    async (requestData: any, thunkApi) => await NetworkService.postAddComplaintData(requestData)
);



const initialState = {
    reasons: [] as Reasons[],
    isLoading: false,
    errorMessage: '',
};

const addComplaintSlice = createSlice({
    name: "addComplaint",
    initialState: {
        ...initialState,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchReasonsList.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
            })
            .addCase(fetchReasonsList.fulfilled, (state, action: any) => {
                state.reasons = action.payload?.reasons;
                state.isLoading = false;
                state.errorMessage = '';
            })
            .addCase(fetchReasonsList.rejected, (state, action) => {
                state.reasons = [];
                state.errorMessage = action.error.message ?? '';
                state.isLoading = false;
            });

        builder
            .addCase(fetchAddComplaintData.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
            })
            .addCase(fetchAddComplaintData.fulfilled, (state, action: any) => {

                state.isLoading = false;
                state.errorMessage = '';
            })
            .addCase(fetchAddComplaintData.rejected, (state, action) => {
                state.reasons = [];
                state.errorMessage = action.error.message ?? '';
                state.isLoading = false;
            });
    },
});

export default addComplaintSlice.reducer;
