import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../network/AuthService";

export const loginThunk = createAsyncThunk(
    '/login',
    async (body: any, thunkApi) => await AuthService.login(body)
)

const companySlice = createSlice({
    name: "login",
    initialState: {
        data: null,
        isLoading: false,
        errorMessage: ''
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loginThunk.pending, (state, action) => {
                state.isLoading = true
                state.errorMessage = ''
            })
            .addCase(loginThunk.fulfilled, (state, action) => {
                state.data = action.payload as any
                state.isLoading = false
                state.errorMessage = ''
            })
            .addCase(loginThunk.rejected, (state, action) => {
                state.errorMessage = action.error.message ?? ''
                state.isLoading = false
            })
    }
})

export default companySlice.reducer;