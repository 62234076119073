import React, { Suspense } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useUserContext } from '../context/UserContext'
import { HOME } from '../routes/routes'
import CustomLoader from '../component/common/CustomLoader'

const AuthLayout = () => {

    const { isLoggedIn } = useUserContext()

    if (!isLoggedIn)
        return (
            < Suspense fallback={< CustomLoader />}>
                <Outlet />
            </Suspense >
        )

    return (
        <Navigate to={HOME} />
    )
}


export default AuthLayout