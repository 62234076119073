import AxiosHelper from "./AxiosHelper"

const login = (body: any) => {
    body.clientIdentifier = 'INVENTORY_WEB';

    return new Promise((resolve, reject) => {
        AxiosHelper
            .httpPost({ path: 'login', queryParams: null, body: body })
            .then((res: any) => {
                if (res.isSuccess) {
                  const { access_token, refresh_token } = res.data;
        
                  // Store the tokens in local storage
                  // localStorage.setItem('access-token', access_token);
                  // localStorage.setItem('refresh-token', refresh_token);
        
                  resolve(res);
                } else {
                  reject(res);
                }
              })
              .catch((error) => {
                reject(error);
              });
          });
        };
        


const AuthService = {
    login
}

export default AuthService