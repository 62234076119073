import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import NetworkService from "../../network/NetworkService"
import { Notification, Offer } from "../../model/OfferDetail";

// Define your Offer interface

export const offerListThunk = createAsyncThunk(
    '/offer-list',
    async (_, thunkApi) => await NetworkService.getListOfOffers()
)

export const notificationListThunk = createAsyncThunk(
    '/notification-list',
    async (_, thunkApi) => await NetworkService.getNotifications()
)

export const offerDetailThunk = createAsyncThunk(
    '/offer-detail',
    async (body: any, thunkApi) => await NetworkService.getOfferDetails(body)
)

const initialOfferData = {
    offerList: [] as Offer[],
    isLoading: false,
    errorMessage: '',
    notificationCount: 0,
    offerDetail: {} as Offer
};

const offerSlice = createSlice({
    name: "offer-list",
    initialState: {
        ...initialOfferData,
    },
    reducers: {
        // You can add any additional actions related to Offer state here if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(offerListThunk.pending, (state, action) => {
                state.isLoading = true;
                state.errorMessage = '';
            })
            .addCase(offerListThunk.fulfilled, (state, action: any) => {
                state.offerList = action.payload?.offers_list as Offer[];
                state.isLoading = false;
                state.errorMessage = '';
            })
            .addCase(offerListThunk.rejected, (state, action) => {
                state.offerList = [];
                state.errorMessage = action.error.message ?? '';
                state.isLoading = false;
            });


        builder
            .addCase(notificationListThunk.pending, (state, action) => {
                state.isLoading = true;
                state.errorMessage = '';
            })
            .addCase(notificationListThunk.fulfilled, (state, action: any) => {
                state.notificationCount = action.payload?.notifications?.length; //itmay or may not 
                state.isLoading = false;
                state.errorMessage = '';
            })
            .addCase(notificationListThunk.rejected, (state, action) => {
                state.errorMessage = action.error.message ?? '';
                state.isLoading = false;
            });



        builder
            .addCase(offerDetailThunk.pending, (state, action) => {
                state.isLoading = true;
                state.errorMessage = '';
            })
            .addCase(offerDetailThunk.fulfilled, (state, action: any) => {
                state.offerDetail = action.payload?.offer_data[0]; //itmay or may not 
                state.isLoading = false;
                state.errorMessage = '';
            })
            .addCase(offerDetailThunk.rejected, (state, action) => {
                state.errorMessage = action.error.message ?? '';
                state.isLoading = false;
            });
    }
});

// Export actions from the Offer slice
// export const { setOfferFilter, setPage, setOfferFilterList } = offerSlice.actions;

export default offerSlice.reducer;
