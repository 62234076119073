import React from 'react';


const Layout = ({ children }: any) => {
     return (
          <>

               {children}
          </>
     )
};
export default Layout;