// Navbar.tsx3

import React, { MouseEvent, useEffect, useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import '../styles/style.scss';
import zeissLogo from '../assets/ZEISS Logo 0/zeiss-logo-rgb.png';
import Badge from '@mui/material/Badge';
import img4 from '../assets/52-na-8082-clear-7-i-flash-original-imagg4ayzpafqzzm 1.jpg'
import img1 from '../assets/51Cuh2FKZIL 1.jpg'
import NotificationsIcon from '@mui/icons-material/Notifications'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { TypeFormatFlags } from 'typescript';
import { Link, useLocation, useNavigate, useRoutes } from 'react-router-dom';
//import NetworkService from '../network/NetworkService';
import SearchIcon from '@mui/icons-material/Search'
import { useUserContext } from '../context/UserContext';
import { COMPLAINTS, HOME, OFFER, ORDERS, PROFILE } from '../routes/routes';
import { NavLink } from 'react-router-dom';
import { Alert, AlertTitle, Autocomplete, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store';
import { orderListThunk, orderSearchListThunk, orderSearchThunk, setOrderFilterList, setOrderList, setPage, setSearchPageNum, setTotalPageCount } from '../redux/slice/orderSlice';
import { complaintListThunk, complaintSearchListThunk, complaintSearchThunk, setCompPage, setCompSearchPageNum, setCompTotalPageCount, setComplaintFilterList, setComplaintList } from '../redux/slice/complaintSlice';
import { setSearchVal } from '../redux/slice/navbarSlice';
import { fetchAutocompleteSuggestions } from '../redux/slice/autoCompleteSlice';
import { Order } from '../model/order';
import { Complaint } from '../model/ComplaintDetail';
import NetworkService from '../network/NetworkService';
import { Offer } from '../model/OfferDetail';
import { notificationListThunk, offerListThunk } from '../redux/slice/offerSlice';
import { CancelOutlined, Label } from '@mui/icons-material';
import { Dropdown } from 'react-bootstrap';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

interface AlertState {
  title: string;
  message: string;
  type: 'success' | 'error';
}
const MainNavbar = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { isLoggedIn } = useUserContext();

  const [profileMenuOpen, setProfileMenuOpen] = useState(false); // State for profile menu
  const { updateSearchState, searchVal } = useSelector((state: RootState) => state.navbar);
  const location = useLocation();
  const PATH = [ORDERS, COMPLAINTS]
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [alert, setAlert] = useState<AlertState | null>(null);
  const dispatch: AppDispatch = useDispatch();

  const { orderFilterList, orderList, tempOrderList, tempPageNumber, tempTotalCount } = useSelector((state: RootState) => state.order);
  const { notificationCount, offerList } = useSelector((state: RootState) => state.offer);
  const { complaintFilterList, tempComplaintList, tempCompPageNumber, tempCompTotalCount } = useSelector((state: RootState) => state.complaint);
  //const [isMobileNavExpanded, setIsMobileNavExpanded] = useState(false);

  const { logout } = useUserContext()
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    fetchOffersList();
    fetchNotificationsCount();
  }, []);


  useEffect(() => {
    if (PATH.includes(location.pathname)) {
      setShowSearchBar(true)//it sets state true
      return
    }
    setShowSearchBar(false)// else f
  }, [updateSearchState]);

  useEffect(() => {
    handleProfileMenuClose()
  }, [location.pathname])

  const handleProfileMenuClick = () => {
    setProfileMenuOpen(true);
  };

  const handleProfileMenuClose = () => {
    setProfileMenuOpen(false);
  };

  const goToDashBoard = () => {
    navigate(HOME)
  }

  const onSearchChange = (e: any, key: string, value: string) => {
    if (e?.type === "change") {
      dispatch(setSearchVal(value))
      const filter = { [key]: value }
      if (location.pathname == ORDERS) {
        fetchOrders(value);
        // dispatch(setPage(1));
        return
      }
      if (location.pathname == COMPLAINTS) {
        fetchComplaints(value);
        // dispatch(setCompPage(1));
        return
      }
    }
  }

  const fetchComplaints = (value: string) => {
    let tempCompFilters = value;
    dispatch(complaintSearchThunk(tempCompFilters))
      .unwrap()
      .then((data: any) => {
        // console.log(data)
        if (data?.complaints && data.complaints.length > 0) {
          dispatch(setComplaintFilterList(data.complaints));
          setAlert({
            title: 'Complaints Found',
            message: 'Complaints successfully fetched.',
            type: 'success',
          });
        } else {
          const errorMessage = data?.message || 'No complaints found.';
          setAlert({
            title: 'No Complaints',
            message: errorMessage,
            type: 'error',
          });
        }
      })
      .catch((error: any) => {
        const errorMessage = error?.message || 'An error occurred while fetching complaints.';
        setAlert({
          title: 'Error',
          message: errorMessage,
          type: 'error',
        });
      });
  };


  const fetchOrders = (value: string) => {
    let tempFilters = value;
    dispatch(orderSearchThunk(tempFilters))
      .unwrap()
      .then((data: any) => {
       
        if (data?.orders && data.orders.length > 0) {
          const successMessage = data?.message || 'Order Found';
          // Handle successful response
          //..
          dispatch(setOrderFilterList(data.orders))
          setAlert({
            title: 'Order Found',
            message: successMessage,
            type: 'success'
          })
        } else {
          const errorMessage = data?.message || 'No orders found.';
          setAlert({
            title: 'No Orders',
            message: errorMessage,
            type: 'error',
          });
        }
      })
      .catch((error: any) => {
        const errorMessage =
          error?.message || 'An error occurred while fetching orders.';
        setAlert({
          title: 'Error',
          message: errorMessage,
          type: 'error',
        });
      });
  };



  const fetchOffersList = () => {
    dispatch(offerListThunk())
      .unwrap()
      .then((data: any) => {

      })
      .catch((error: any) => {

      });
  };

  const fetchNotificationsCount = () => {
    dispatch(notificationListThunk())
      .unwrap()
      .then((data: any) => {

      })
      .catch((error: any) => {

      });
  };

  const onSelectFilter = async (selectedValue: any) => {
    if (selectedValue?.value) {
      try {
        if (location.pathname === ORDERS) {
          await dispatch(orderSearchListThunk(selectedValue.value));
          dispatch(setSearchPageNum(1));
          dispatch(setTotalPageCount(1));
        } else if (location.pathname === COMPLAINTS) {
          // Call the searchComplaint API with the selected value
          await dispatch(complaintSearchListThunk(selectedValue.value)); // Assuming..
          dispatch(setCompSearchPageNum(1));
          dispatch(setCompTotalPageCount(1));
        }
      } catch (error) {
        console.error('Error fetching details:', error);
      }
      return;
    }
    resetList();
  };

  const clear = () => {
    setSearchVal('');
    resetList();
    //complaintReset();
    dispatch(setOrderFilterList([]))
    dispatch(setComplaintFilterList([]))
    dispatch(setSearchVal(''));
  }

  const resetList = () => {
    dispatch(setOrderList(tempOrderList)) //it will change when we click
    dispatch(setPage(tempPageNumber));
    dispatch(setTotalPageCount(tempTotalCount))
    dispatch(setComplaintList(tempComplaintList))

    dispatch(setCompPage(tempCompPageNumber));
    // dispatch(setSearchPageNum(tempCompPageNumber));
    dispatch(setCompTotalPageCount(tempCompTotalCount))
  }

  const [openNavbar, setOpenNavbar] = useState(false);

  const navItems = [
    {name: 'Orders', link: '/orders'},
    {name: 'Complaints', link: '/complaints'},
    {name: 'Offers', link: '/offers'},
  ];

  useEffect(() => {
    setOpenNavbar(false);
  }, [navigate]);

  const handleLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
    // Check if the href contains a "#"
    if (event.currentTarget.href.endsWith('#')) {
      event.preventDefault();

      // Extract the hash part from the href
      const hash = event.currentTarget.href.split('#')[1];

      // Scroll to the element with the corresponding id
      const targetElement = document.getElementById(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <> 
      <div className='shadow-sm border-bottom position-sticky top-0 z-3 bg-white'>
        <div className="container-fluid px-3 px-md-5 py-2 py-md-3 m-0">
          <div className='nav-wrapper d-flex flex-row align-items-center py-0'>
            <Link to="/">
              {/* <Image src={'/logo-blue-no-border.png'} width={1000} height={1000} alt='Faze 3 logo' /> */}
              <img src={zeissLogo} className='logo text-dark my-auto pointer' onClick={goToDashBoard} />
            </Link>

            <div className="d-flex d-md-none gap-2 align-items-center justify-content-end flex-grow-1">
              <div className='fs-5 ms-md-4 p-2 p-md-0 menu-item '>
                <Link className='text-decoration-none' onClick={handleLinkClick} to={'/'} id="notifyBtn" data-bs-toggle="dropdown" aria-expanded="false">
                {notificationCount > 0 && (
                  <Badge badgeContent={notificationCount} color="error">
                    <NotificationsIcon />
                  </Badge>
                )}
                </Link>

                <ul className="dropdown-menu notify-dropdown py-0" aria-labelledby="notifyBtn">
                  {offerList.map((offer) => (
                    <Link to={`${OFFER}?offerId=${offer.id}`} className='link-style' key={offer.id}>
                      <li className='d-flex flex-row nav-link py-1'>
                        <img src={img1} className='img1 mx-2 my-auto' />
                        <div className='px-3'>
                          <div className='text-grey fs-x-sm-semibold'>{offer.offer_title}</div>
                          {/* <div className='fs-sm-light'>{offer.offer_desc}</div> */}
                        </div>
                      </li>
                    </Link>
                  ))}
                </ul>
              </div>
              <button className=' btn p-1 text-primary' onClick={e => setOpenNavbar(!openNavbar)}>
                {openNavbar ? <CloseIcon /> : <MenuIcon />}
              </button>
            </div>
            <div className={`menu-wrapper ${openNavbar ? 'open-menu' : 'close-menu'}`}>
              <ul className='m-0 d-flex flex-md-row flex-column align-items-md-center ms-md-4'>
                {
                  navItems.map((item, outerIndex) => (
                    <li className='fs-5 ms-md-3 p-2 p-md-0 menu-item' key={outerIndex}>
                      <Link className='text-decoration-none' onClick={handleLinkClick} to={item.link}>
                        <span className={`nav-style ${location.pathname === item.link ? 'text-primary nav-active' : 'text-black'}`}>{item.name}</span>
                      </Link>
                    </li>
                  ))
                }
                <li className='fs-5 ms-md-3 p-2 p-md-0 menu-item d-block d-md-none d-lg-none'>
                  <Link className='text-decoration-none' onClick={handleLinkClick} to={'/profile'}>
                    <span className={`nav-style ${location.pathname === '/profile' ? 'text-primary nav-active' : 'text-black'}`}>Profile</span>
                  </Link>
                </li>
                <li className='fs-5 ms-md-3 p-2 p-md-0 menu-item d-block d-md-none d-lg-none' onClick={logout}>
                  <Link className='text-decoration-none' onClick={handleLinkClick} to={'/'}>
                    <span className={`nav-style text-black`}>Sign out</span>
                  </Link>
                </li>
              </ul>
            </div>

            <div className="d-flex align-items-center">
              <div className='fs-5 ms-md-4 p-2 p-md-0 d-none d-md-block d-lg-block'>
                <Link className='text-decoration-none' onClick={handleLinkClick} to={'/'} id="notifyBtn" data-bs-toggle="dropdown" aria-expanded="false">
                
                  <Badge badgeContent={(notificationCount && notificationCount>0) ? notificationCount : '0'} color="error">
                    <NotificationsIcon />
                  </Badge>
               
                </Link>
                
                <ul className="dropdown-menu notify-dropdown py-0" aria-labelledby="notifyBtn">
                {notificationCount > 0 ? (
                  <>
                  {offerList.map((offer) => (
                    <Link to={`${OFFER}?offerId=${offer.id}`} className='link-style' key={offer.id}>
                      <li className='d-flex flex-row nav-link py-1'>
                        <img src={img1} className='img1 mx-2 my-auto' />
                        <div className='px-3'>
                          <div className='text-grey fs-x-sm-semibold'>{offer.offer_title}</div>
                          {/* <div className='fs-sm-light'>{offer.offer_desc}</div> */}
                        </div>
                      </li>
                    </Link>
                  ))}
                  </>
                  ): 'No Notifications'} 
                </ul>
               
              </div>

              <Dropdown>
                <Dropdown.Toggle as="a" className="fs-5 ms-md-4 p-2 p-md-0 menu-item d-none d-md-block d-lg-block" id="accountDropdown">
                  <AccountCircleIcon fontSize='large' />
                </Dropdown.Toggle>
                <Dropdown.Menu className="round-dropdown-menu rounded">
                  <Dropdown.Item onClick={() => { handleProfileMenuClose(); navigate('/profile'); }}>Profile</Dropdown.Item>
                  <Dropdown.Item onClick={logout}>Sign out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>

        <nav className="navbar navbar-expand-lg d-none">
      <div className="container-fluid">
        <img src={zeissLogo} className='logo text-dark p-2 my-auto pointer' onClick={goToDashBoard} />
        <div className="dropdown d-md-none d-lg-none ms-auto px-4">
          {/* <SearchIcon className='mx-4' /> */}
          <button className="border-0 bg-transparent" id="notifyBtn" data-bs-toggle="dropdown" aria-expanded="false">
            <Badge badgeContent={notificationCount} color="error">
              <NotificationsIcon />
            </Badge>
          </button>
          <ul className="dropdown-menu notify-dropdown py-0" aria-labelledby="notifyBtn">
            {offerList.map((offer) => (
              <Link to={`${OFFER}?offerId=${offer.id}`} className='link-style' key={offer.id}>
                <li className='d-flex flex-row nav-link py-1'>
                  <img src={img1} className='img1 mx-2 my-auto' />
                  <div className='px-3'>
                    <div className='text-grey fs-x-sm-semibold'>{offer.offer_title}</div>
                    {/* <div className='fs-sm-light'>{offer.offer_desc}</div> */}
                  </div>
                </li>
              </Link>
            ))}
          </ul>
        </div>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
          <span className="navbar-close-icon" aria-hidden="true">X</span>
        </button>
        <div className="collapse navbar-collapse "
        // ${isMobileNavExpanded ? "expanded" : ""}`}
         id="navbarSupportedContent">
          {/* <button
            className="close-icon d-block d-md-none d-lg-none"
            // onClick={() => setIsMobileNavExpanded(false)}
            >
            <span aria-hidden="true">&times;</span>
          </button> */}
          <ul className="navbar-nav">
            {/* <div onClick={clear}>Clear</div> */}
            {alert && (
              <Alert severity={alert.type} onClose={() => setAlert(null)}>
                <AlertTitle>{alert.title}</AlertTitle>
                {alert.message}
              </Alert>
            )}
            <div className='ms-auto px-5 d-none'>
              {showSearchBar ? (
                <Autocomplete
                  freeSolo
                  id="search"
                  options={
                    (location.pathname === ORDERS && searchVal.trim() !== '')
                      ? orderFilterList.map((filter: Order) => ({
                        label: `${filter.product_name} - ${filter.order_date} - ${filter.orderheadid}`,
                        value: filter.orderheadid,
                      }))
                      : (location.pathname === COMPLAINTS && searchVal.trim() !== '')
                        ? complaintFilterList.map((filter: Complaint) => ({
                          label: `${filter.product_name} - ${filter.comp_date} - ${filter.comp_no}`,
                          value: filter.comp_no,
                        }))
                        : []
                  }

                  loading={
                    (location.pathname === ORDERS && searchVal.trim() !== '' && !alert)
                      ? !orderFilterList.length
                      : (location.pathname === COMPLAINTS && searchVal.trim() !== '' && !alert)
                        ? !complaintFilterList.length
                        : false
                  }


                  onChange={(event: any, selectedValue: any) => {
                    if (location.pathname === ORDERS) {
                      onSelectFilter(selectedValue);
                    } else if (location.pathname === COMPLAINTS) {
                      onSelectFilter(selectedValue);
                    }
                  }}

                  onInputChange={(event, value) => {
                    onSearchChange(event, 'search', value);
                  }}

                  value={searchVal}
                  sx={{ width: 300 }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="Search"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {searchVal && ( // Only render if searchVal is not empty
                              <>
                                <SearchIcon />
                                <CancelOutlined onClick={clear} className='pointer' />
                              </>
                            )}
                          </>
                        )
                      }}
                    />
                  )}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option; // handle the case when option is just a string
                    }
                    return option.label;
                  }}
                />
              ) : null}
            </div>
            {isLoggedIn && (
              <>
                <li className="nav-item">
                  <NavLink to="/home" className="nav-link link-style nav-style flex-start" data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show" aria-current="page">Home</NavLink >
                </li>
                <li className="nav-item">
                  <NavLink to="/orders" className="nav-link  link-style nav-style" aria-current="page">Orders</NavLink >
                </li>
                <li className="nav-item">
                  <NavLink to="/complaints" className="nav-link link-style nav-style">Complaints</NavLink >
                </li>
                <li className="nav-item">
                  <NavLink to="/offers" className="nav-link link-style nav-style">Offers</NavLink></li>
                <li className="nav-item">
                  <NavLink to="/profile" className="nav-link link-style nav-style d-block d-md-none d-lg-none">Profile</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/login" className="nav-link link-style nav-style d-block d-md-none d-lg-none" onClick={logout}>Sign out</NavLink>
                </li>
                {/* <li className="nav-item">
                  <div className="dropdown d-none d-md-block d-lg-block ms-auto mt-2">
                    <button className="border-0 bg-transparent" id="notifyBtn" data-bs-toggle="dropdown" aria-expanded="false">
                      <Badge badgeContent={notificationCount} color="error">
                        <NotificationsIcon />
                      </Badge>
                    </button>
                    <ul className="dropdown-menu notify-dropdown py-0" aria-labelledby="notifyBtn">
                      {offerList.map((offer) => (
                        <Link to={`${OFFER}?offerId=${offer.id}`} className='link-style' key={offer.id}>
                          <li className='d-flex flex-row nav-link py-1'>
                            <img src={img1} className='img1 mx-2 my-auto' />
                            <div className='px-3'>
                              <div className='text-grey fs-x-sm-semibold'>{offer.offer_title}</div> */}
                {/* <div className='fs-sm-light'>{offer.offer_desc}</div> */}
                {/* </div>
                          </li>
                        </Link>
                      ))}
                    </ul>
                  </div>
                </li> */}
              </>
            )}
            {/* {isLoggedIn && (    
                
              <li className="nav-item"> */}

            {/* <AccountCircleIcon fontSize='large' className='pic profile-pic d-none d-md-block d-lg-block' onClick={handleProfileMenuClick} /> */}
            {/* <li className="nav-item"> */}
            {/* <Dropdown>
                    <Dropdown.Toggle as="a" className="pic profile-pic d-none d-md-block d-lg-block dropdown-toggle" id="accountDropdown">
                      <AccountCircleIcon fontSize='large' className='pic profile-pic' />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="round-dropdown-menu rounded">
                      <Dropdown.Item onClick={() => { handleProfileMenuClose(); navigate('/profile'); }}>Profile Info</Dropdown.Item>
                      <Dropdown.Item onClick={logout}>Signout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
            {/* </li> */}
            {/* <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}      

                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                className='d-none d-md-block d-lg-block'
              >      


                {offerList.map((offer) => (
                  <Link to={`${OFFER}?offerId=${offer.id}`} className='link-style'>
                    <MenuItem key={offer.id} onClick={handleClose} className='custom-menu'>
                      <img src={img1} className='img1' />
                      <div className='img1-txt mx-2'>{offer.offer_title}</div> */}
            {/* <div className='img1-txt1 mt-5'>{offer.offer_desc}</div> */}
            {/* </MenuItem>
                  </Link>
                ))}    
              </Menu> */}

            {/* <Menu
                  anchorEl={anchorEl}
                  open={profileMenuOpen}
                  onClose={handleProfileMenuClose}                                               
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  className='d-none d-md-block d-lg-block'
                >
                  <MenuItem className='fs-sm-lg-semibold' onClick={() => {
                    handleProfileMenuClose();
                    navigate('/profile');
                  }}>Profile</MenuItem>
                  <MenuItem className='fs-sm-lg-semibold' onClick={logout}>Logout</MenuItem>
                </Menu> */}
            {/* </li>)} */}
          </ul>
          <div className="dropdown d-none d-md-block d-lg-block ms-auto mt-2">
            {/* <SearchIcon className='mx-2 ' /> */}
            <button className="border-0 bg-transparent" id="notifyBtn" data-bs-toggle="dropdown" aria-expanded="false">
              <Badge badgeContent={notificationCount} color="error">
                <NotificationsIcon />
              </Badge>
            </button>
            <ul className="dropdown-menu notify-dropdown py-0" aria-labelledby="notifyBtn">
              {offerList.map((offer) => (
                <Link to={`${OFFER}?offerId=${offer.id}`} className='link-style' key={offer.id}>
                  <li className='d-flex flex-row nav-link py-1'>
                    <img src={img1} className='img1 mx-2 my-auto' />
                    <div className='px-3'>
                      <div className='text-grey fs-x-sm-semibold'>{offer.offer_title}</div>
                      {/* <div className='fs-sm-light'>{offer.offer_desc}</div> */}
                    </div>
                  </li>
                </Link>
              ))}
            </ul>
          </div>
          <Dropdown>
            <Dropdown.Toggle as="a" className="pic profile-pic d-none d-md-block d-lg-block dropdown-toggle mt-3" id="accountDropdown">
              <AccountCircleIcon fontSize='large' className='pic profile-pic' />
            </Dropdown.Toggle>
            <Dropdown.Menu className="round-dropdown-menu rounded">
              <Dropdown.Item onClick={() => { handleProfileMenuClose(); navigate('/profile'); }}>Profile</Dropdown.Item>
              <Dropdown.Item onClick={logout}>Sign out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </nav>
    </>
  );
};

export default MainNavbar;
